/* eslint-disable no-unused-vars */

import Creative from "../Creative";
import groups from "./groups";
import {combo} from "./helpers";

function getGroups() {
  return [
    groups.face,
    groups.skin,
    groups.head,
  ];
}

function createCombo(comboParams) {
  const templateParams = {
    canvas_template_name: comboParams.position[1],
    mask_template_name: comboParams.position[2],
    gender: "#0",
    seeds: comboParams.seed,
    prompt: comboParams.prompt,
    negative_prompt: comboParams.negativePrompt,
    strength: comboParams.strength,
    guessmode: "true",
    ctrlnet_modules: "none",
    sampler_name: "DPM$$ 2M Karras",
    num_steps: 30,
    ...comboParams.templateParams,
  };

  const steps = [
    {id: "gender_classifier"},
  ];

  const fileImages = [{src: "@" + comboParams.fileIndex}];

  ("" + comboParams.faceTemplateChain)
    .split(",")
    .map((i) => i.trim())
    .filter((i) => i.length > 0)
    .forEach((item, index) => {
      const step = {id: item};
      if (index === 0) {
        step.images = fileImages;
      }
      steps.push(step)
    });

  const mainTemplateStep = {id: 8739, templateParams};
  if (steps.length === 1) {
    mainTemplateStep.images = fileImages;
  }

  steps.push(mainTemplateStep);

  let nameSegments = [];
  steps.forEach((step, index) => {
    if (index > 0) {
      nameSegments.push(step.id);
    }
  })

  const name = nameSegments.join("_") + ",seed:" + comboParams.seed + `,str:${comboParams.strength}`;
  const item = combo(steps);
  item.setGroup(comboParams.position[0]);
  item.setExtra(Creative.EXTRA_NAME, name);

  return item;
}

function getCreatives(
  fileIndex,
  seeds,
  prompt,
  negativePrompt,
  strengths,
  faceTemplateChain,
  ctrlnetWeight
) {
  const results = [];
  const positions = [
    [groups.face, 8697, 8698],
    // [groups.skin, 8661, 8662],
    [groups.head, 8695, 8696],
  ];

  positions.forEach((position) => {
    seeds.forEach((seed) => {
      strengths.forEach((strength) => {
        const templateParams = {};

        if (ctrlnetWeight.length > 0) {
          templateParams.ctrlnet_modules = ctrlnetWeight;
        }

        const combo = createCombo({
          fileIndex,
          position,
          seed: seed,
          prompt,
          negativePrompt,
          strength,
          faceTemplateChain,
          templateParams
        });

        combo.setExtra("group_1", position[0]);
        combo.setExtra("group_2", seed);
        combo.setExtra("group_3", strength);

        results.push(combo);
      });
    });
  });

  return results;
}

export default {
  getGroups: function() {
    return getGroups();
  },
  getCreatives: function(fileIndex, seeds, prompt, negativePrompt, strengths, faceTemplateChain, ctrlnetWeight) {
    return getCreatives(fileIndex, seeds, prompt, negativePrompt, strengths, faceTemplateChain, ctrlnetWeight);
  },
};
